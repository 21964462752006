import { styled, Text } from '@grupoboticario/flora-react'
import { Table } from '@grupoboticario/flora-team-react-table'

const HeaderCell = styled(Table.Header, {
  minWidth: '$$mbWidth',
  width: '$$mbWidth',

  '& * + *': {
    marginLeft: 'unset',
  },

  '@tablet': {
    minWidth: '$$tbWidth',
    width: '$$tbWidth',
  },

  '@desktop': {
    minWidth: '$$dkWidth',
    width: '$$dkWidth',
  },
})

const StyledText = styled(Text, {
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '$1',
  flexWrap: 'nowrap',
  textTransform: 'none !important',
})

export { HeaderCell, StyledText }
