import { styled } from '@grupoboticario/flora'
import { Box } from '@grupoboticario/flora-react'

export const Container = styled(Box, {
  maxHeight: 8 * 56 + 64,
  overflow: 'scroll',
  '&::-webkit-scrollbar': {
    '-webkit-appearance': 'none',
    backgroundColor: '$disabledBackground',
    borderRadius: 40,
    '&:vertical': {
      width: '$1',
    },
    '&:horizontal': {
      height: '$1',
    },
  },
  '&::-webkit-scrollbar-thumb': {
    borderRadius: 40,
    backgroundColor: '$actionableDefault',
  },
})
