import { Step } from '../activation-register.types'
import { copyStep, dispatchStep, machine, registerStep } from '../config/steps'
import { useRegisterStepsContext } from '../providers'

interface UseRegisterSteps {
  list: Step[]
  current: Step
  back: () => void
  reset: () => void
  setList: (steps: Step[]) => void
}

const useRegisterSteps = (): UseRegisterSteps => {
  const { list, setList } = useRegisterStepsContext()
  const current = list.find((s) => s.status === 'current') as Step

  const back = () => {
    const sm = machine[current.id]
    if (sm.on.back) setList(sm.on.back)
  }

  const reset = () => {
    setList([copyStep('current'), dispatchStep('await'), registerStep('await')])
  }

  return {
    current,
    setList,
    reset,
    back,
    list,
  }
}

export { useRegisterSteps }
