import { styled, Text } from '@grupoboticario/flora-react'
import { Table } from '@grupoboticario/flora-team-react-table'

const StyledTableCell = styled(Table.Cell, {
  position: 'relative',
  overflow: 'hidden',
  transitionProperty: 'background-color',
  transitionDuration: '0.2s',
  transitionTimingFunction: 'ease-out',

  '& * + * ': {
    marginLeft: 'initial',
  },

  variants: {
    checked: {
      true: {
        backgroundColor: '$nonPrimaryButtonActive !important',
      },
    },
  },
})

const TableCellContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  whiteSpace: 'nowrap',
  gap: '$2',
})

const StyledText = styled(Text, {
  textTransform: 'capitalize',
})

export { StyledTableCell, StyledText, TableCellContainer }
