import { styled } from '@grupoboticario/flora'
import { Tag } from '@grupoboticario/flora-react'

const StyledTag = styled(Tag, {
  color: '$nonInteractivePredominant !important',
  backgroundColor: '$backgroundSecondary !important',
  borderLeftWidth: '4px',
})

export { StyledTag }
