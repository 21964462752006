import React, { PropsWithChildren, useEffect, useState } from 'react'

import {
  FloraButton as Button,
  Modal,
  ModalBody,
  ModalContent,
  Text,
  Toaster,
} from '@grupoboticario/flora-react'

import { StyledModalFooter, StyledModalHeader } from '@shared/components'
import { useEvents } from '@shared/events'
import { pt } from '@shared/locales'

import { useRegister, useRegisterSteps } from '../../hooks'

const RegisterModal: React.FC<PropsWithChildren> = ({ children }) => {
  const [open, setOpen] = useState(false)
  const register = useRegister()
  const steps = useRegisterSteps()
  const events = useEvents()
  const inLastStep = steps.list[2].status === 'current'

  useEffect(() => {
    const openModal = (ev: CustomEvent) => {
      const resellers = ev.detail.map((r: { id: number }) => r.id)
      const cycles = ev.detail.map((r: { cycleCode: number }) => r.cycleCode?.toString())
      const sectors = ev.detail.map((r: { sectors: number[] }) => r.sectors?.join(','))
      register.dispatch({
        type: 'change-resellers',
        payload: { resellers, cycles, sectors, data: ev.detail },
      })
      setOpen(true)
    }

    const closeModal = () => {
      steps.reset()
      setOpen(false)
    }

    window.addEventListener('segmentation:activation-register', openModal)
    window.addEventListener('segmentation:close-activation-register', closeModal)
    return () => {
      window.removeEventListener('segmentation:activation-register', openModal)
      window.removeEventListener('segmentation:close-activation-register', closeModal)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const sendRegister = async () => {
    const ok = await register.finish()

    if (ok) {
      setOpen(false)
      steps.reset()
      Toaster.notify({
        kind: 'success',
        origin: 'right-top',
        description: pt.registerModal.registerSuccess,
      })
    } else {
      Toaster.notify({
        kind: 'error',
        origin: 'right-top',
        description: pt.registerModal.registerFailed,
      })
    }
  }

  const onClickButtonBack = (): void => {
    register.dispatch({ type: 'change-reason', payload: { text: '', success: false } })
    steps.back()
    events.clickRegisterBack()
  }

  return (
    <Modal open={open}>
      <ModalContent>
        <StyledModalHeader>
          <Text size="subTitleDeskMedium" weight="medium">
            {pt.registerModal.sendActivation}
          </Text>
        </StyledModalHeader>

        <ModalBody>{children}</ModalBody>

        {inLastStep && (
          <StyledModalFooter>
            <Button
              size="small"
              hierarchy="tertiary"
              disabled={register.loading}
              onClick={onClickButtonBack}
            >
              {pt.registerModal.back}
            </Button>

            <Button
              size="small"
              disabled={!register.isValid || register.loading}
              onClick={sendRegister}
            >
              {pt.registerModal.register}
            </Button>
          </StyledModalFooter>
        )}
      </ModalContent>
    </Modal>
  )
}

export { RegisterModal }
