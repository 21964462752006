const connectionSurnames = ['da', 'de', 'do', 'das', 'dos']

export const toFirstLastName = (fullName: string): string => {
  const splittedName = fullName.trim().split(' ')

  if (splittedName.length > 1) {
    if (connectionSurnames.includes(splittedName[splittedName.length - 2].toLocaleLowerCase())) {
      return `${splittedName[0]} ${splittedName[splittedName.length - 2]} ${
        splittedName[splittedName.length - 1]
      }`
    }
    return `${splittedName[0]} ${splittedName.pop()}`
  }

  return splittedName[0]
}
