import { format } from 'date-fns'
import React, { type ReactNode } from 'react'

import { ShoppingBagIcon } from '@grupoboticario/flora-react-icons'
import { toLocaleDate, toLocaleTime } from '@grupoboticario/vdi-mfe-utils'

import { useFilter, useOrdination, usePagination, useResellerList } from '@reseller-list/hooks'
import { Reseller } from '@reseller-list/reseller-list.types'
import { DataTable } from '@shared/components'
import { useEvents } from '@shared/events'
import { pt } from '@shared/locales'
import { useDrawerContext } from '@shared/providers'

import { useFeatureFlag } from 'configcat-react'
import { getColumns } from '../../config'
import { Alert } from './list-table.styles'

interface ListTableProps {
  middle: ReactNode
  showTags?: boolean
}

const ListTable: React.FC<ListTableProps> = ({ middle, showTags }) => {
  const {
    error,
    filled,
    loading,
    resellers,
    getList,
    updatedAt,
    isExtendedOrdering,
    extendedOrderingPeriod,
    resellersFull,
  } = useResellerList()

  const { value: enableActivationRegister } = useFeatureFlag('enableActivationRegister', false)
  const { value: showPhoneColumn } = useFeatureFlag('phoneColumn', false)
  const { value: showNexRangeValue } = useFeatureFlag('nextRangeColumn', false)
  const { value: isOrdenable } = useFeatureFlag('ordenable', false)

  const pagination = usePagination()
  const ordination = useOrdination()
  const drawer = useDrawerContext()
  const filter = useFilter()
  const events = useEvents()

  const openResellerDetail = (reseller: Reseller): void => {
    const re = { ...reseller, updatedAt }
    drawer.open('reseller-detail', re)
  }

  const columns = getColumns(
    showPhoneColumn,
    showNexRangeValue,
    isOrdenable,
    isExtendedOrdering,
    openResellerDetail,
    () => events.clickCopyData('lote'),
    () => events.clickCopyData('individual'),
  )

  const subtitle = updatedAt
    ? pt.listTable.subtitle(toLocaleDate(updatedAt), toLocaleTime(updatedAt))
    : ''

  const heading = pagination.totalRecords ? pt.listTable.heading(pagination.totalRecords) : ''

  const onCopyData = (detail: Reseller[]) => {
    if (enableActivationRegister) {
      window.dispatchEvent(new CustomEvent('segmentation:activation-register', { detail }))
    }
  }

  const bottomMiddle = isExtendedOrdering && filled && (
    <Alert>
      <ShoppingBagIcon color="$statusInfoNonInteractiveEmphasis" size="24px" />
      {pt.dataTable.extendedOrdering(
        format(extendedOrderingPeriod.start, 'dd/MM/yyyy'),
        format(extendedOrderingPeriod.end, 'dd/MM/yyyy'),
      )}
    </Alert>
  )

  return (
    <DataTable
      heading={heading}
      subtitle={subtitle}
      records={resellers}
      allRecords={resellersFull}
      ordination={ordination}
      pagination={pagination}
      columns={columns}
      filter={{ ...filter, showTags }}
      slots={{ middle, bottomMiddle }}
      onCheckRecord={() => events.clickCheckboxCopy('individual')}
      onCheckAllRecords={() => events.clickCheckboxCopy('lote')}
      onCopyRecords={() => events.clickCopyRecords()}
      actions={{ onCopyData }}
      state={{
        error,
        loading,
        filled,
        onErrorBtnClick: getList,
      }}
    />
  )
}

export { ListTable }
