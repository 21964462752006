import { type PropsWithChildren } from 'react'

import { Container } from './tag.styles'

interface TagProps extends PropsWithChildren {
  upTo?: Date
}

const NewTag: React.FC<TagProps> = ({ upTo }) => {
  const isVisible = !upTo || upTo > new Date()

  return !isVisible ? null : (
    <Container>
      <span>Novo!</span>
    </Container>
  )
}

export { NewTag }
