type Event = EventGA3 | EventGA4

interface EventGA3 extends Record<string, string> {
  event: 'event'
  action: string
  category: string
  label: string
}

interface EventGA4 {
  event: 'analytics-event'
  event_parameters: Record<string, string>
}

enum ActionType {
  clickButton = 'clique:botao',
}

enum CategoryType {
  base = 'vd-interativa:ativacao',
  activation = 'vd-interativa:ativacao:ativacao',
}

enum LabelType {
  linting = 'listagem_',
  openDetails = 'abrir_detalhes_',
  startAttendance = 'iniciar_atendimento_',
  pagination = 'paginacao_',
}

enum InteractionDetailType {
  click = 'click',
  checkbox = 'checkbox',
  interactionModal = 'interaction:modal',
  reasonTrigger = 'motivo:acionamento',
  contactChannelTrigger = 'canal-de-contato:acionamento',
  observationTrigger = 'observacao:acionamento',
  observation = 'observacao',
  popupRegisterTrigger = 'popup_registro_acionamento',
}

export { ActionType, CategoryType, InteractionDetailType, LabelType, type Event }
