import { FloraButton, Input } from '@grupoboticario/flora-react'
import { EcommSearchIcon, ExclamationMarkHexagonFillIcon } from '@grupoboticario/flora-react-icons'
import { pt } from '@shared/locales/pt-br'
import { useFiltersContext } from '@shared/providers'
import { useTableContext } from '../table-provider'
import { HelperTextContainer, SearchContainer } from './filter-code.styles'

const FilterCode: React.FC = () => {
  const { filterCode, changeFilterCode } = useFiltersContext()
  const { state, records } = useTableContext()
  const disabled = !state.filled
  const filters = useFiltersContext()

  const showHelperText = () => (!disabled && filterCode && !records.length) || false

  const renderHelperText = showHelperText() ? (
    <HelperTextContainer>
      <ExclamationMarkHexagonFillIcon />
      <span>{pt.filterCode.helperText}</span>
    </HelperTextContainer>
  ) : null

  const getData = () => {
    filters.cleanFilters()
    filters.changeFilters({ resellerId: filterCode })
    window.dispatchEvent(new CustomEvent('segmentation:find-by-id'))
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.replace(/\D/g, '')
    changeFilterCode(value)
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && filterCode) {
      getData()
    }
  }

  return (
    <SearchContainer>
      <Input
        id="phone"
        labelText={pt.filterCode.label}
        optionalText=""
        placeholder={pt.filterCode.placeholder}
        validText="Error message"
        invalid={showHelperText()}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        value={filterCode}
        disabled={disabled}
        rightIcon={
          <FloraButton
            hierarchy="tertiary"
            size="small"
            value={filterCode}
            disabled={disabled || filterCode.length < 3}
            onClick={() => getData()}
          >
            <EcommSearchIcon aria-hidden />
          </FloraButton>
        }
      />

      {renderHelperText}
    </SearchContainer>
  )
}

export { FilterCode }
