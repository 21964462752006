import { Fragment } from 'react'

import { FloraButton as Button, FloraTypography as Typography } from '@grupoboticario/flora-react'
import { MinusCircleIcon, ThreeLinesHorizontal2Icon } from '@grupoboticario/flora-react-icons'

import { Badge, NewTag } from '@shared/components'
import { pt } from '@shared/locales'

import { FilterCode } from '../filter-code'
import { useTableContext } from '../table-provider'
import { ButtonsContainer, Container } from './heading.styles'

import { useFeatureFlag } from 'configcat-react'

const Heading = (): JSX.Element => {
  const { heading, filter, state, subtitle } = useTableContext()
  const disableFilterButton = !state.filled && !state.error
  const { value: filterByCode } = useFeatureFlag('filterByCode', false)

  const count = filter?.tags
    .map((tag) => tag.id)
    .filter((id, index, list) => list.indexOf(id) === index).length

  const renderSubtitle = subtitle && (
    <Typography
      fontSize="exceptionsAuxiliar"
      fontWeight="medium"
      color="$nonInteractiveAuxiliar"
      css={{ display: 'block' }}
    >
      {subtitle}
    </Typography>
  )

  const renderCodeFilter = filterByCode ? (
    <>
      <NewTag upTo={new Date('2025-04-25')} />
      <FilterCode />
    </>
  ) : null

  return (
    <Fragment>
      <Container>
        <Typography fontSize="bodyLargeStandard" fontWeight="medium" as="h3">
          {heading}
          {renderSubtitle}
        </Typography>

        {filter && (
          <ButtonsContainer>
            {renderCodeFilter}

            <Badge text={`+${count}`} show={Boolean(count)}>
              <Button
                hierarchy="secondary"
                has="iconLeft"
                icon={<ThreeLinesHorizontal2Icon />}
                onClick={filter.onFilter}
                disabled={disableFilterButton}
              >
                {pt.dataTable.filter}
              </Button>
            </Badge>

            <Button
              hierarchy="secondary"
              has="iconLeft"
              icon={<MinusCircleIcon />}
              onClick={filter.clearFilters}
              disabled={disableFilterButton || !count}
            >
              {pt.dataTable.clearFilters}
            </Button>
          </ButtonsContainer>
        )}
      </Container>
    </Fragment>
  )
}

export { Heading }
