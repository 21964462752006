import { styled } from '@grupoboticario/flora-react'

const Container = styled('div', {
  all: 'unset',
  backgroundColor: '#0DAB01',
  borderRadius: '4px',
  padding: '2px 4px 2px 4px',
  fontSize: '12px',
  fontWeight: '300',
  color: 'white',
})

export { Container }
