import { memo, useId } from 'react'

import {
  FloraButton as Button,
  FloraCheckbox as Checkbox,
  Tooltip,
} from '@grupoboticario/flora-react'
import { ChevronRightIcon } from '@grupoboticario/flora-react-icons'
import { Table } from '@grupoboticario/flora-team-react-table'
import { toCurrency, toPhoneNumber } from '@grupoboticario/vdi-mfe-utils'

import { useEvents } from '@shared/events'
import { pt } from '@shared/locales'

import {
  DataTableCurrencyColumn,
  DataTableInactivityColumn,
  DataTableNameColumn,
  type DataTableBtnColumn,
  type DataTablePhoneColumn,
  type DataTableStatusColumn,
  type DataTableTextColumn,
} from '../../data-table.types'

import { InactivityTag } from '@shared/components/inactivity-tag'
import { ResellerStatusBadge } from '@shared/components/reseller-status-badge'
import { toFirstLastName } from '@shared/utils/to-first-last-name'
import { StyledTableCell, StyledText, TableCellContainer } from './body-cell.styles'

interface BodyCellTextProps<T> extends BodyCellBaseProps<T> {
  column: DataTableTextColumn<T>
  checked: boolean
}

interface BodyCellBaseProps<T> {
  record: T
}

const BodyCellText = memo(<T,>({ record, column, checked }: BodyCellTextProps<T>): JSX.Element => {
  const property = record[column.key]

  return (
    <StyledTableCell asChild align="left" checked={checked && column.copyable}>
      <TableCellContainer>
        <StyledText size="bodyMediumShort" color="$nonInteractivePredominant" weight="medium">
          {property || '---'}
        </StyledText>
      </TableCellContainer>
    </StyledTableCell>
  )
})

BodyCellText.displayName = 'BodyCellText'

interface BodyCellBtnProps<T> extends BodyCellBaseProps<T> {
  record: T
  column: DataTableBtnColumn<T>
}

const BodyCellBtn = memo(<T,>({ record, column }: BodyCellBtnProps<T>): JSX.Element => {
  const events = useEvents()

  const onButtonClick = () => {
    events.clickAdditionalInformation()
    column.onBtnClick(record, column)
  }

  return (
    <Table.Cell>
      <Button
        size="small"
        aria-label={column.label}
        hierarchy="secondary"
        has="iconOnly"
        onClick={onButtonClick}
        icon={<ChevronRightIcon size={16} color="$actionableDefault" />}
      />
    </Table.Cell>
  )
})

BodyCellBtn.displayName = 'BodyCellBtn'

interface BodyCellCheckboxProps {
  onCheck: () => void
  checked: boolean
}

const BodyCellCheckbox = ({ onCheck, checked }: BodyCellCheckboxProps): JSX.Element => {
  const id = useId()

  return (
    <StyledTableCell asChild align="left" checked={checked}>
      <Checkbox
        id={id}
        onChange={onCheck}
        checked={checked}
        aria-label={pt.dataTable.checkboxRecordLabel}
      />
    </StyledTableCell>
  )
}

interface BodyCellCurrentProps<T> extends BodyCellBaseProps<T> {
  column: DataTableCurrencyColumn<T>
  checked: boolean
}

const BodyCurrencyText = memo(
  <T,>({ record, column, checked }: BodyCellCurrentProps<T>): JSX.Element => {
    const property = record[column.key]

    return (
      <StyledTableCell asChild align="left" checked={checked && column.copyable}>
        <TableCellContainer>
          <StyledText size="bodyMediumShort" color="$nonInteractivePredominant" weight="medium">
            {property ? toCurrency(property as number) : '---'}
          </StyledText>
        </TableCellContainer>
      </StyledTableCell>
    )
  },
)

BodyCurrencyText.displayName = 'BodyCurrencyText'

interface BodyPhoneCurrentProps<T> extends BodyCellBaseProps<T> {
  column: DataTablePhoneColumn<T>
  checked: boolean
}

const BodyPhoneText = memo(
  <T,>({ record, column, checked }: BodyPhoneCurrentProps<T>): JSX.Element => {
    const property = record[column.key]

    return (
      <StyledTableCell asChild align="left" checked={checked && column.copyable}>
        <TableCellContainer>
          <StyledText size="bodyMediumShort" color="$nonInteractivePredominant" weight="medium">
            {property ? toPhoneNumber(property as string) : '---'}
          </StyledText>
        </TableCellContainer>
      </StyledTableCell>
    )
  },
)

BodyPhoneText.displayName = 'BodyPhoneText'

interface BodyNameCurrentProps<T> extends BodyCellBaseProps<T> {
  column: DataTableNameColumn<T>
  checked: boolean
}

const BodyNameText = memo(
  <T,>({ record, column, checked }: BodyNameCurrentProps<T>): JSX.Element => {
    const fullName = (record[column.key] as string).toLowerCase()
    const property = toFirstLastName(fullName)

    return (
      <StyledTableCell asChild align="left" checked={checked && column.copyable}>
        <TableCellContainer>
          <Tooltip
            direction="top"
            align="center"
            text={fullName}
            showArrow
            css={{ textTransform: 'capitalize' }}
          >
            <StyledText size="bodyMediumShort" color="$nonInteractivePredominant" weight="medium">
              {property || '---'}
            </StyledText>
          </Tooltip>
        </TableCellContainer>
      </StyledTableCell>
    )
  },
)

BodyNameText.displayName = 'BodyNameText'

interface BodyInactivityCurrentProps<T> extends BodyCellBaseProps<T> {
  column: DataTableInactivityColumn<T>
  checked: boolean
}

const BodyInactivityTag = memo(
  <T,>({ record, column, checked }: BodyInactivityCurrentProps<T>): JSX.Element => {
    return (
      <StyledTableCell asChild align="left" checked={checked}>
        <TableCellContainer>
          <InactivityTag inactivityLevel={record[column.key] as string} />
        </TableCellContainer>
      </StyledTableCell>
    )
  },
)

BodyInactivityTag.displayName = 'BodyInactivityTag'

interface BodyStatusTagCurrentProps<T> extends BodyCellBaseProps<T> {
  column: DataTableStatusColumn<T>
  checked: boolean
}

const BodyStatusTag = memo(
  <T,>({ record, column, checked }: BodyStatusTagCurrentProps<T>): JSX.Element => {
    return (
      <StyledTableCell asChild align="center" checked={checked}>
        <TableCellContainer>
          <ResellerStatusBadge blocked={record[column.key] as boolean} />
        </TableCellContainer>
      </StyledTableCell>
    )
  },
)

BodyStatusTag.displayName = 'BodyStatusTag'

export {
  BodyCellBtn,
  BodyCellCheckbox,
  BodyCellText,
  BodyCurrencyText,
  BodyInactivityTag,
  BodyNameText,
  BodyPhoneText,
  BodyStatusTag,
}
