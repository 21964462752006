import { Fragment } from 'react'

import { Flex, FloraTypography as Typography } from '@grupoboticario/flora-react'

import { useApiData } from '@shared/api'
import { AccordionItem, DataBlock, DataSection, List } from '@shared/components'
import { pt } from '@shared/locales'

import { ContainerText, ShoppingBagIcon } from './shopping-history.styles'
import { type ResellerTopProductDTO } from './shopping-history.types'

interface ShoppingHistoryProps {
  resellerId?: string
}

const ShoppingHistory = ({ resellerId }: ShoppingHistoryProps): JSX.Element => {
  const { data, error, filled, loading, getData, notFound } = useApiData<ResellerTopProductDTO>({
    dataSource: 'getTopPurchasedProducts',
    params: { resellerId },
  })

  return (
    <DataSection
      data={data}
      filled={filled}
      loading={loading}
      error={error}
      notFound={notFound}
      onRetry={getData}
      fallbackHeight={120}
      title={pt.shoppingHistory.title}
      render={(dataModel) => (
        <DataBlock
          description={pt.shoppingHistory.subtitle}
          content={
            <Fragment>
              {!data?.length && (
                <Typography
                  as="p"
                  fontSize="bodyLargeStandard"
                  fontWeight="medium"
                  color="$alert-standard"
                >
                  {pt.shoppingHistory.notEnoughData}
                </Typography>
              )}

              {data && data.length > 0 && (
                <List
                  type="accordion"
                  data={[dataModel]}
                  renderItem={(item) => (
                    <AccordionItem
                      title={pt.shoppingHistory.topPurcheasedProducts}
                      content={
                        <Flex direction="column" gap="$6">
                          {item?.map(({ productDescription }, index) => (
                            <ContainerText key={index}>
                              <ShoppingBagIcon color="$actionableAltContent" size={16} />
                              <Typography as="p" fontSize="bodySmallShort" fontWeight="medium">
                                {productDescription}
                              </Typography>
                            </ContainerText>
                          ))}
                        </Flex>
                      }
                    />
                  )}
                />
              )}
            </Fragment>
          }
        />
      )}
    />
  )
}

export { ShoppingHistory }
