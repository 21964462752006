import {
  FloraButton as Button,
  Flex,
  FloraTypography as Typography,
} from '@grupoboticario/flora-react'

import { pt } from '@shared/locales'

import { Container } from './error-state.styles'

interface ErrorStateProps {
  description?: string
  height?: string
  icon?: React.ReactNode
  onButtonClick?: () => void
  title?: string
}

const ErrorState = ({
  onButtonClick,
  description,
  title,
  height,
  icon,
}: ErrorStateProps): JSX.Element => (
  <Container
    data-testid="error-state"
    align="center"
    justify="center"
    direction="column"
    css={{ height: height ?? 'auto' }}
  >
    <Flex direction="column" gap="$4" align="center" justify="center">
      {icon}

      <Flex direction="column" gap="$2" align="center" justify="center">
        <Typography
          as="h5"
          fontSize="bodyLargeShort"
          color="$nonInteractivePredominant"
          fontWeight="medium"
        >
          {title ?? pt.common.errorState.title}
        </Typography>

        {description && (
          <Typography fontSize="bodySmallShort" color="$nonInteractiveAuxiliar">
            {description}
          </Typography>
        )}
      </Flex>

      {onButtonClick && (
        <Button hierarchy="secondary" onClick={onButtonClick}>
          {pt.common.errorState.buttonText}
        </Button>
      )}
    </Flex>
  </Container>
)

export { ErrorState, type ErrorStateProps }
