import { styled } from '@grupoboticario/flora-react'

const Container = styled('div', {
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gridColumnGap: '$4',
  gridRowGap: '$4',
  gap: '$4',
  paddingBottom: '$4',

  '& [data-flora="input-wrapper"]': {
    minHeight: 'unset',
  },

  '& [data-flora="multiple-select"]': {
    paddingTop: '$5 !important',
  },

  'span.subtitle': { fontSize: '14px', fontWeight: 400 },
})

const FormGroup = styled('div', {
  gridColumn: '1 / -1',

  variants: {
    gridType: {
      full: {
        gridColumn: '1 / -1',
      },

      half: {
        '@desktop': {
          gridColumn: 'span 1',
        },
      },
    },
  },
})

export { Container, FormGroup }
