import { CheckSingleIcon, CrossIcon } from '@grupoboticario/flora-react-icons'

interface ResellerStatusProps {
  blocked: boolean
}

const ResellerStatusBadge: React.FC<ResellerStatusProps> = ({ blocked }) => {
  console.log(blocked)
  if (blocked) {
    return <CrossIcon color="$error-standard" />
  }
  return <CheckSingleIcon color="$success-standard" />
}

export { ResellerStatusBadge }
