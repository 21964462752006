import { Reseller } from '@reseller-list/reseller-list.types'

export const resellersToClipboard = async (columns: string[], data: Reseller[]): Promise<void> => {
  const dataToCopy = data
    .map((reseller) => {
      let row = ''

      row += `${reseller.id}\t`
      if (columns.includes('name')) row += `${reseller.name ?? reseller.name}`
      row += '\t'
      if (columns.includes('creditBalance'))
        row += `${reseller.creditBalance ?? reseller.creditBalance}`
      row += '\t'
      if (columns.includes('nextRangeValue'))
        row += `${reseller.nextRangeValue ?? reseller.nextRangeValue}`
      row += '\t'
      if (columns.includes('phone')) row += `${reseller.phone ? reseller.phone : ''}`

      return row
    })
    .join('\n')

  await navigator.clipboard.writeText(dataToCopy)
}
