import { type DataTableBtnColumn, type DataTableColumn } from '@shared/components'
import { pt } from '@shared/locales'

import { toCurrency } from '@grupoboticario/vdi-mfe-utils'

import { type Reseller } from '../reseller-list.types'

const getColumns = (
  showPhoneColumn: boolean,
  showNextRangeValue: boolean,
  isExtendedOrdering: boolean,
  onBtnClick: DataTableBtnColumn<Reseller>['onBtnClick'],
  onCopyAll?: () => void,
  onCopySingle?: () => void,
): Array<DataTableColumn<Reseller>> => {
  const columns: Array<DataTableColumn<Reseller> | null> = [
    {
      type: 'text',
      label: pt.resellerList.columns.id,
      key: 'id',
      onCopyAll,
      onCopySingle,
      copyable: true,
      widths: { mb: 'auto', tb: '30%', dk: '10%' },
    },
    {
      type: 'text',
      label: pt.resellerList.columns.name,
      key: 'name',
      copyable: true,
      onBeforeCopy: (value: string) => {
        const nameParts = value?.trim().split(' ')
        const firstName = nameParts?.[0] ?? ''

        const lastName = nameParts?.[nameParts.length - 1] ?? ''
        const lastNameFirstLetter = lastName?.[0] ?? ''
        return `${firstName} ${lastNameFirstLetter}.`
      },
      onCopyAll,
      onCopySingle,
      widths: { mb: 'auto', tb: '50%', dk: '25%' },
    },
    {
      type: 'currency',
      label: pt.resellerList.columns.creditBalance,
      key: 'creditBalance',
      copyable: true,
      hidden: ['mobile'],
      onCopyAll,
      onCopySingle,
      onBeforeCopy: (value) => toCurrency(value as number),
      widths: { mb: 'auto', tb: 'auto', dk: '15%' },
    },
    showNextRangeValue
      ? {
          type: 'currency',
          label: pt.resellerList.columns.nextRangeValue,
          key: 'nextRangeValue',
          copyable: true,
          hidden: ['mobile'],
          onCopyAll,
          onCopySingle,
          widths: { mb: 'auto', tb: 'auto', dk: '13%' },
        }
      : null,
    showPhoneColumn
      ? {
          type: 'phone',
          label: pt.resellerList.columns.phone,
          key: 'phone',
          copyable: true,
          onCopyAll,
          onCopySingle,
          widths: { mb: 'auto', tb: 'auto', dk: '15%' },
          onBeforeCopy: (value: string) => (value ? `55${value}` : ''),
        }
      : null,
    {
      type: 'text',
      label: pt.resellerList.columns.inactiveLevel,
      key: 'inactiveLevel',
      hidden: ['mobile'],
      widths: { mb: 'auto', tb: '20%', dk: '10%' },
    },
    isExtendedOrdering
      ? {
          type: 'text',
          label: pt.resellerList.columns.currentCycle,
          key: 'currentCycle',
          widths: { mb: 'auto', tb: 'auto', dk: '15%' },
          tooltip: pt.resellerList.currentCycleTooltip,
          hidden: ['mobile'],
        }
      : null,
    {
      type: 'status',
      label: pt.resellerList.columns.status,
      key: 'blocked',
      status: 'error',
      hidden: ['mobile', 'tablet'],
      widths: { mb: 'auto', tb: 'auto', dk: '20%' },
      getStatusProps(blocked) {
        return {
          status: blocked ? 'error' : 'success',
          value: blocked
            ? pt.resellerList.columns.statusBlocked
            : pt.resellerList.columns.statusUnblocked,
        }
      },
    },
    {
      type: 'text',
      label: pt.resellerList.columns.lastAttendance,
      key: 'lastTabulation',
      hidden: ['mobile', 'tablet'],
      widths: { mb: 'auto', tb: 'auto', dk: '15%' },
    },
    {
      type: 'btn',
      label: '',
      onBtnClick,
      widths: { mb: 'auto', tb: 'auto', dk: '5%' },
    },
  ]

  return columns.filter((c) => c !== null)
}

export { getColumns }
