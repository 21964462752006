import React from 'react'

import {
  Dropdown,
  DropdownItem,
  Pagination,
  FloraTypography as Typography,
} from '@grupoboticario/flora-react'

import { pt } from '@shared/locales'

import { useTableContext } from '../table-provider'

import { Container, ItemsPerPageContainer } from './table-pagination.styles'

const TablePagination: React.FC = () => {
  const {
    pagination: { onItemsPerPageChange, onPaginate, ...pagination },
    records,
  } = useTableContext()

  const { tablePagination } = pt

  const onChange = (page: number): void => {
    onPaginate(page + 1)
  }

  const onChangePagination = (event: React.ChangeEvent<HTMLInputElement>) => {
    onItemsPerPageChange(Number(event.target.value))
  }

  const pageTotalRecords = records.length

  return (
    <Container>
      <Typography fontSize="bodySmallShort" as="p">
        {tablePagination.showingRecords(pageTotalRecords, pagination.totalRecords)}
      </Typography>

      <Pagination
        {...pagination}
        page={pagination.page - 1}
        onChange={onChange}
        css={{ margin: '0 auto' }}
      />

      <ItemsPerPageContainer>
        <Dropdown
          id="show-number-items"
          as="div"
          labelText={tablePagination.dropdownLabel}
          onChange={onChangePagination}
          value={pagination.itemsPerPage}
        >
          <DropdownItem value={100}>100</DropdownItem>
          <DropdownItem value={150}>150</DropdownItem>
          <DropdownItem value={200}>200</DropdownItem>
        </Dropdown>
      </ItemsPerPageContainer>
    </Container>
  )
}

export { TablePagination }
