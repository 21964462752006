import { useCallback, useEffect, type FormEvent } from 'react'

import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  FloraButton as Button,
  FloraTypography as Typography,
} from '@grupoboticario/flora-react'

import { pt } from '@shared/locales'
import { useFiltersContext, type FilterValues } from '@shared/providers'
import { isEmpty } from '@shared/utils'

import { FilterFields } from './modules'
import { FilterActionContainer, Form } from './objectives-filter.styles'
import { useObjectiveFormContext } from './providers'

interface ObjectivesFilterProps {
  formId: string
  onSumbit: () => void
}

const ObjectivesFilter: React.FC<ObjectivesFilterProps> = ({ formId, onSumbit }) => {
  const { form, setForm } = useObjectiveFormContext()
  const filters = useFiltersContext()

  useEffect(() => {
    setForm(filters.current)
  }, [filters, setForm])

  const submitFilters = (ev: FormEvent<HTMLFormElement>): void => {
    ev.preventDefault()
    filters.changeFilterCode('')
    filters.changeFilters({ ...form, resellerId: undefined })
    onSumbit()
  }

  const onChange = useCallback(
    (value: FilterValues): void => {
      setForm((f) => ({ ...f, ...value }))
    },
    [setForm],
  )

  return (
    <Form id={formId} onSubmit={submitFilters}>
      <Accordion type="multiple" startOpen>
        {filters.fieldsGroups.map((group, index) => (
          <AccordionItem value={index.toString()} key={index}>
            <AccordionButton>
              <Typography fontSize="bodyLargeStandard" fontWeight="medium">
                {group.description}
              </Typography>
            </AccordionButton>

            <AccordionPanel>
              <FilterFields fields={group.fields} form={form} onChange={onChange} />
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </Form>
  )
}

interface ObjectivesFilterActionProps {
  formId: string
}

const ObjectivesFilterAction: React.FC<ObjectivesFilterActionProps> = ({ formId }) => {
  const { form } = useObjectiveFormContext()
  const filters = useFiltersContext()
  const formCopy = { ...form }
  delete formCopy.sectorId
  const isFormEmpty = isEmpty(formCopy)

  const clean = (): void => {
    filters.cleanFilters()
  }

  return (
    <FilterActionContainer>
      <Button
        onClick={clean}
        hierarchy="secondary"
        aria-label={pt.objectivesFilter.clearBtn.ariaLabel}
        disabled={isFormEmpty}
      >
        {pt.objectivesFilter.clearBtn.label}
      </Button>

      <Button
        type="submit"
        form={formId}
        aria-label={pt.objectivesFilter.applyBtn.ariaLabel}
        disabled={isFormEmpty}
      >
        {pt.objectivesFilter.applyBtn.label}
      </Button>
    </FilterActionContainer>
  )
}

export { ObjectivesFilter, ObjectivesFilterAction }
