import { AsyncState, MetaState, useApi, useAsyncState } from '@shared/api'
import { useEvents } from '@shared/events'

import { useFiltersContext } from '@shared/providers'
import { RegisterContextProps, useRegisterContext } from '../providers'

import { useFeatureFlag } from 'configcat-react'

interface UseRegister extends AsyncState, Pick<RegisterContextProps, 'dispatch'> {
  isValid: boolean
  text: string
  channel: string
  observation: string
  finish: () => Promise<boolean>
}

const useRegister = (): UseRegister => {
  const events = useEvents()
  const { state, dispatch } = useRegisterContext()
  const status = useAsyncState(state.status)
  const api = useApi()
  const filters = useFiltersContext()
  const { value: showContactChannel } = useFeatureFlag('contactChannel', false)
  const isValid =
    (state.success &&
      !!state.text &&
      (!showContactChannel || (!!state.channel && state.observation.length >= 15))) ||
    (!state.success && state.text.length >= 15)

  const finish = async () => {
    if (!isValid) {
      return false
    }

    const textField = state.success
      ? {
          reason: {
            label: state.text,
            id: state.id,
          },
          contactChannel: state.channel,
          note: state.observation,
        }
      : { failReason: state.text }

    const activation = {
      success: state.success,
      resellers: state.resellers,
      cycles: state.cycles,
      sectors: state.sectors,
      ...textField,
      filter: filters.current,
    }

    if (state.success) {
      events.clickRegister('click')
    } else {
      events.clickRegister('observation', state.text.slice(0, 80).trim())
    }

    dispatch({ type: 'change-status', payload: MetaState.Loading })
    const activationCleaned = clearEmptyFields(activation)
    const result = await api.registerActivations(activationCleaned)

    if (result.ok) {
      if (activation.reason?.label) {
        events.clickRegisterReason(activation.reason.label)
      }
      if (activation.contactChannel) {
        events.clickRegisterContactChannel(activation.contactChannel)
      }
      if (activation.note) {
        events.clickRegisterObservation(activation.note)
      }

      dispatch({ type: 'change-status', payload: MetaState.Fullfilled })
      dispatch({ type: 'reset-state' })
    } else {
      dispatch({ type: 'change-status', payload: MetaState.Error })
    }

    return result.ok
  }

  const clearEmptyFields = (activation: Record<string, unknown>) => {
    const activationCopy = { ...activation }
    const activationKeys = Object.keys(activationCopy)

    activationKeys.forEach((key) => {
      const propertie = activationCopy[key]
      if (typeof propertie !== 'boolean' && !propertie) {
        delete activationCopy[key]
      }
    })

    return activationCopy
  }

  return {
    isValid,
    finish,
    dispatch,
    text: state.text,
    channel: state.channel,
    observation: state.observation,
    ...status,
  }
}

export { useRegister }
