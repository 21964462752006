import { FloraTypography as Typography } from '@grupoboticario/flora-react'
import { StyledTag } from './inactivity-tag.styles'

interface InactivityTagProps {
  inactivityLevel: string
}

const InactivityTag: React.FC<InactivityTagProps> = ({ inactivityLevel }) => {
  return (
    <StyledTag variant="disabled" css={{ borderLeftColor: getInactivityColor(inactivityLevel) }}>
      <Typography fontSize="bodySmallShort" fontWeight="medium" color="$nonInteractivePredominant">
        {inactivityLevel}
      </Typography>
    </StyledTag>
  )
}

const getInactivityColor = (level: string) => {
  switch (level) {
    case 'A0':
    case 'A1':
      return '$assorted6'
    case 'A2':
      return '$assorted5'
    case 'A3':
      return '$assorted4'
    case 'I4':
      return '$assorted3'
    case 'I5':
    case 'I6':
      return '$assorted2'
    default:
      return '$statusErrorNonInteractiveEmphasis'
  }
}

export { InactivityTag }
