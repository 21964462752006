import { globalCss, styled } from '@grupoboticario/flora-react'

const AppContainer = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
})

const AppWrapper = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '$4',
  width: '100vw',

  '@tablet': {
    width: 'calc(100vw - 95px)',
  },
})

const AppContent = styled('div', {
  display: 'flex',
  gap: '$1',
  width: '100%',
  flexDirection: 'column',
  maxWidth: '1440px',
})

const globalStyles = globalCss({
  'a, h1, h2, h3, h4, h5, h6, label, p, span': {
    fontSmooth: 'auto',
    '-webkit-font-smoothing': 'subpixel-antialiased',
    '-moz-osx-font-smoothing': 'grayscale',
    margin: 0,
  },

  html: {
    overflowY: 'auto',
  },
})

const Title = styled('h1', {
  color: '$nonInteractivePredominant',
  fontSize: '$subtitleDesk',
  fontWeight: '$medium',
  marginBottom: '$1',
})

export { AppContainer, AppContent, AppWrapper, globalStyles, Title }
