/* eslint-disable @typescript-eslint/no-explicit-any */

import { type FilterField } from '@shared/providers'

import {
  CheckboxField,
  DateRangeField,
  DropdownField,
  DropdownFieldAsync,
  InputField,
  SwitchField,
} from '../../components'

import { type ObjectivesFormHandler } from '../../objectives-filter.types'
import { Container, FormGroup } from './filter-fields.styles'

interface FilterFieldsProps extends ObjectivesFormHandler<any> {
  fields: FilterField[]
}

interface RenderFieldTypeProps {
  disabled: boolean
  field: FilterField
}

const FilterFields = ({ fields, ...props }: FilterFieldsProps): JSX.Element => {
  const renderFieldType = ({ disabled, field }: RenderFieldTypeProps): JSX.Element | null => {
    switch (field.type) {
      case 'dropdown':
        return <DropdownField disabled={disabled} {...field} {...props} />
      case 'dropdown-async':
        return <DropdownFieldAsync disabled={disabled} {...field} {...props} />
      case 'date-range':
        return <DateRangeField disabled={disabled} {...field} {...props} />
      case 'checkbox':
        return <CheckboxField disabled={disabled} {...field} {...props} />
      case 'switch':
        return <SwitchField disabled={disabled} {...field} {...props} />
      case 'input':
        return <InputField disabled={disabled} {...field} {...props} />
      case 'subtitle':
        return <span className="subtitle">{field.label}</span>
      default:
        return null
    }
  }

  const renderFields = fields.map((field, index) => {
    const disabled = field.isDisabled?.(props.form) ?? false

    return (
      <FormGroup key={`${index}-${field.id}`} gridType={field.grid === 'half' ? 'half' : 'full'}>
        {renderFieldType({ disabled: disabled, field: field })}
      </FormGroup>
    )
  })

  return <Container>{renderFields}</Container>
}

export { FilterFields }
