import { styled } from '@grupoboticario/flora'

const ActionsContainer = styled('div', {
  display: 'flex',
  gap: '$2',
  justifyContent: 'end',
  marginTop: '$2',
  paddingTop: '$2',
  borderTop: '1px solid $statusAlertNonInteractiveOutline',
})

export { ActionsContainer }
