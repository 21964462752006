import { styled } from '@grupoboticario/flora-react'

const CopyStepContainer = styled('div', {
  display: 'flex',
  gap: '$6',
  flexDirection: 'column',
})

const CheckboxContainer = styled('div', {
  display: 'flex',
  flexWrap: 'wrap',
  gap: '$2',
})

const ActionsContainer = styled('div', {
  display: 'flex',
  gap: '$2',
  justifyContent: 'end',
  marginTop: '$2',
  paddingTop: '$2',
  borderTop: '1px solid $statusAlertNonInteractiveOutline',
})

export { ActionsContainer, CheckboxContainer, CopyStepContainer }
