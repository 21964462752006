import { useCallback, useMemo } from 'react'

import { formatDate } from 'date-fns'

import { type DataTableFilter, type DataTableTag } from '@shared/components'
import { useEvents } from '@shared/events'
import { useDrawerContext, useFiltersContext, type FilterName } from '@shared/providers'

type ListFilters = Record<string, string | number | string[] | number[] | undefined>

const useFilter = (): DataTableFilter => {
  const filters = useFiltersContext()
  const drawer = useDrawerContext()
  const events = useEvents()

  const fields = filters.fieldsGroups.flatMap((g) =>
    g.fields.filter((f) => f.type !== 'subtitle' && f.type !== 'newtag'),
  )

  const getFieldById = (id: string) => {
    const field = fields.find((f) => f.id === id)
    return field
  }

  const getFieldByFilterName = (name: string) => {
    const field = fields.find((f) => f.filters.find((f) => f.name === name))
    return field
  }

  const fromFilterToTag = useCallback((name: string, value: string) => {
    const field = getFieldByFilterName(name)
    if (!field) return { id: 1, label: '', value }

    const filter = field.filters.find((f) => f.name === name) as FilterName
    const tag = { id: field.id, label: filter.label, value }

    switch (field?.type) {
      case 'date-range':
        tag.value = formatDate(value, 'dd/MM/yyyy')
        break
      default:
        break
    }

    return tag
  }, [])

  const clearFilters = () => {
    filters.cleanFilters()
  }

  const tags = useMemo(() => {
    const tags: DataTableTag[] = []
    const currents = Object.entries(filters.current)

    for (const [name, values] of currents) {
      if (name === 'sectorId' || name === 'resellerId' || !values) continue

      if (Array.isArray(values)) {
        for (const value of values) {
          tags.push(fromFilterToTag(name, String(value)))
        }
      } else {
        tags.push(fromFilterToTag(name, String(values)))
      }
    }

    return tags
  }, [filters, fromFilterToTag])

  const onFilterRemove = (tag: DataTableTag): void => {
    const field = getFieldById(tag.id as string)
    if (!field) return

    const filterNames = field.filters.map((f) => f.name)
    const valuePurge = tag.value
    // Filtros que aplicam dois filtros (ex: date range)
    // deve limpar ambos pois são usados em conjunto
    const comings = clearFilterValue(valuePurge, filterNames)
    const currents = filters.current
    filters.changeFilters({ ...currents, ...comings })
  }

  const clearFilterValue = (valuePurge: string, filterNames: string[]): ListFilters => {
    const comings: ListFilters = {}

    filterNames.forEach((name) => {
      const valueCurrent = filters.current[name]
      // Filtros de valores múltiplos (ex: multiple select)
      // não queremos remover todos os valores
      if (Array.isArray(valueCurrent)) {
        comings[name] = valueCurrent.filter((v) => String(v) !== valuePurge)
      } else {
        comings[name] = undefined
      }
    })

    return comings
  }

  return {
    onFilter: () => {
      drawer.open('objectives')
      events.clickFilter()
    },
    onFilterRemove,
    clearFilters,
    tags,
  }
}

export { useFilter }
