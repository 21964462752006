import { styled } from '@grupoboticario/flora-react'

const RegisterStepsContainer = styled('div', {
  display: 'flex',
  alignItems: 'stretch',
  flexDirection: 'column',
  gap: '$6',
})

const StepItemsContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$2',
  backgroundColor: '$backgroundSecondary',
  borderRadius: '$medium',
  padding: '$6',

  '@mobile': {
    flexDirection: 'row',
  },
})

const StepComponentContainer = styled('div', {})

export { RegisterStepsContainer, StepComponentContainer, StepItemsContainer }
