import React from 'react'

import { FloraSwitch as Switch, FloraTypography as Typography } from '@grupoboticario/flora-react'

import { type FilterFieldSwitch } from '@shared/providers'

import { type ObjectivesFormHandler } from '../../objectives-filter.types'
import { Container } from './switch-field.styles'

type SwitchFieldProps = FilterFieldSwitch & ObjectivesFormHandler<string | boolean>

const SwitchField: React.FC<SwitchFieldProps> = ({ filters, form, label, onChange }) => {
  const [filter] = filters
  const value = form[filter.name]
  const id = `filter-field-${filter.name}`

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    onChange({ [filter.name]: e.currentTarget.checked ? 'true' : '' })
  }

  return (
    <Container>
      <label htmlFor={id}>
        <Typography fontSize="bodySmallShort">{label}</Typography>
      </label>
      <Switch id={id} onChange={handleChange} checked={value === 'true'} />
    </Container>
  )
}

export { SwitchField }
