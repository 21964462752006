import { Reseller } from '@reseller-list/reseller-list.types'

export const resellersToClipboard = async (columns: string[], data: Reseller[]): Promise<void> => {
  const dataToCopy = data
    .map((reseller) => {
      let row = ''

      row += `${reseller.id}\t`
      if (columns.includes('name')) {
        const resellerFirstName = reseller.name?.trim().split(' ')[0]
        const capitalizedName =
          resellerFirstName[0].toUpperCase() + resellerFirstName.slice(1).toLowerCase()
        row += capitalizedName
      }
      row += '\t'
      if (columns.includes('creditBalance'))
        row += `${reseller.creditBalance ?? reseller.creditBalance}`
      row += '\t'
      if (columns.includes('nextRangeValue'))
        row += `${reseller.nextRangeValue ?? reseller.nextRangeValue}`
      row += '\t'
      if (columns.includes('phone')) row += `${reseller.phone ? reseller.phone : ''}`

      return row
    })
    .join('\n')

  await navigator.clipboard.writeText(dataToCopy)
}
