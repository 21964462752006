import { type DataTableBtnColumn, type DataTableColumn } from '@shared/components'
import { pt } from '@shared/locales'

import { toCurrency } from '@grupoboticario/vdi-mfe-utils'

import { type Reseller } from '../reseller-list.types'

const getColumns = (
  showPhoneColumn: boolean,
  showNextRangeValue: boolean,
  isOrdenable: boolean,
  isExtendedOrdering: boolean,
  onBtnClick: DataTableBtnColumn<Reseller>['onBtnClick'],
  onCopyAll?: () => void,
  onCopySingle?: () => void,
): Array<DataTableColumn<Reseller>> => {
  const columns: Array<DataTableColumn<Reseller> | null> = [
    {
      type: 'text',
      label: pt.resellerList.columns.id,
      key: 'id',
      onCopyAll,
      onCopySingle,
      copyable: true,
      ordenable: isOrdenable,
      widths: { mb: 'auto', tb: 'auto', dk: 'auto' },
    },
    {
      type: 'name',
      label: pt.resellerList.columns.name,
      key: 'name',
      copyable: true,
      ordenable: isOrdenable,
      onBeforeCopy: (value: string) => {
        const nameParts = value?.trim().split(' ')
        const firstName = nameParts?.[0] ?? ''

        const lastName = nameParts?.[nameParts.length - 1] ?? ''
        const lastNameFirstLetter = lastName?.[0] ?? ''
        return `${firstName} ${lastNameFirstLetter}.`
      },
      onCopyAll,
      onCopySingle,
      widths: { mb: 'auto', tb: '50%', dk: '25%' },
    },
    {
      type: 'inactivity',
      label: pt.resellerList.columns.inactiveLevel,
      key: 'inactiveLevel',
      ordenable: isOrdenable,
      widths: { mb: 'auto', tb: '20%', dk: '10%' },
    },
    {
      type: 'currency',
      label: pt.resellerList.columns.creditBalance,
      key: 'creditBalance',
      copyable: true,
      ordenable: isOrdenable,
      onCopyAll,
      onCopySingle,
      onBeforeCopy: (value) => toCurrency(value as number),
      widths: { mb: '130px', tb: '130px', dk: '130px' },
    },
    showNextRangeValue
      ? {
          type: 'currency',
          label: pt.resellerList.columns.nextRangeValue,
          key: 'nextRangeValue',
          copyable: true,
          ordenable: isOrdenable,
          hidden: ['mobile'],
          onCopyAll,
          onCopySingle,
          widths: { mb: '130px', tb: '130px', dk: '130px' },
        }
      : null,
    showPhoneColumn
      ? {
          type: 'phone',
          label: pt.resellerList.columns.phone,
          key: 'phone',
          copyable: true,
          ordenable: isOrdenable,
          onCopyAll,
          onCopySingle,
          widths: { mb: '130px', tb: '130px', dk: '130px' },
          onBeforeCopy: (value: string) => (value ? `55${value}` : ''),
        }
      : null,
    isExtendedOrdering
      ? {
          type: 'text',
          label: pt.resellerList.columns.currentCycle,
          key: 'currentCycle',
          ordenable: isOrdenable,
          widths: { mb: 'auto', tb: 'auto', dk: '15%' },
          tooltip: pt.resellerList.currentCycleTooltip,
        }
      : null,
    {
      type: 'status',
      label: pt.resellerList.columns.status,
      key: 'blocked',
      ordenable: isOrdenable,
      widths: { mb: '110px', tb: '110px', dk: '110px' },
    },
    {
      type: 'text',
      label: pt.resellerList.columns.lastAttendance,
      key: 'lastTabulation',
      ordenable: isOrdenable,
      widths: { mb: 'auto', tb: 'auto', dk: '15%' },
    },
    {
      type: 'btn',
      label: '',
      onBtnClick,
      widths: { mb: 'auto', tb: 'auto', dk: '5%' },
    },
  ]

  return columns.filter((c) => c !== null)
}

export { getColumns }
