import { styled } from '@grupoboticario/flora-react'

const SearchContainer = styled('div', {
  width: '200px',
  display: 'flex',
  flexDirection: 'column',

  'div[data-flora="input-wrapper"]': {
    minHeight: '56px',
  },

  span: {
    fontSize: 'var(--flora--fontSizes-bodySmall)',
  },
})

const HelperTextContainer = styled('div', {
  display: 'flex',
  flefxDirection: 'row',
  gap: '4px',

  svg: {
    color: '#DE1616',
    width: '16px',
    height: '16px',
  },
})

export { HelperTextContainer, SearchContainer }
