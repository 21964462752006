import React, { PropsWithChildren, useEffect, useState } from 'react'

import {
  FloraTypography,
  Modal,
  ModalBody,
  ModalContent,
  Toaster,
} from '@grupoboticario/flora-react'

import { StyledModalHeader } from '@shared/components'
import { pt } from '@shared/locales'

import { useRegister, useRegisterSteps } from '../../hooks'

const RegisterModal: React.FC<PropsWithChildren> = ({ children }) => {
  const [open, setOpen] = useState(false)
  const register = useRegister()
  const steps = useRegisterSteps()

  useEffect(() => {
    const openModal = (ev: CustomEvent) => {
      const resellers = ev.detail.map((r: { id: number }) => r.id)
      const cycles = ev.detail.map((r: { cycleCode: number }) => r.cycleCode?.toString())
      const sectors = ev.detail.map((r: { sectors: number[] }) => r.sectors?.join(','))
      register.dispatch({
        type: 'change-resellers',
        payload: { resellers, cycles, sectors, data: ev.detail },
      })
      setOpen(true)
    }

    const closeModal = () => {
      steps.reset()
      setOpen(false)
    }

    const sendRegister = async () => {
      const ok = await register.finish()

      if (ok) {
        setOpen(false)
        steps.reset()
        Toaster.notify({
          kind: 'success',
          origin: 'right-top',
          description: pt.registerModal.registerSuccess,
        })
      } else {
        Toaster.notify({
          kind: 'error',
          origin: 'right-top',
          description: pt.registerModal.registerFailed,
        })
      }
    }

    window.addEventListener('segmentation:activation-register', openModal)
    window.addEventListener('segmentation:close-activation-register', closeModal)
    window.addEventListener('segmentation:send-activation-register', sendRegister)
    return () => {
      window.removeEventListener('segmentation:activation-register', openModal)
      window.removeEventListener('segmentation:close-activation-register', closeModal)
      window.removeEventListener('segmentation:send-activation-register', sendRegister)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Modal open={open}>
      <ModalContent>
        <StyledModalHeader>
          <FloraTypography fontSize="subtitle">{pt.registerModal.sendActivation}</FloraTypography>
        </StyledModalHeader>

        <ModalBody>{children}</ModalBody>
      </ModalContent>
    </Modal>
  )
}

export { RegisterModal }
