import { ChangeEvent, useId, useState } from 'react'

import {
  FloraButton as Button,
  Flex,
  FloraButton,
  FloraTextarea as Textarea,
  Toaster,
  FloraTypography as Typography,
} from '@grupoboticario/flora-react'

import { pt } from '@shared/locales'

import { useFeatureFlag } from 'configcat-react'

import { useApiData } from '@shared/api'
import { LoadingState } from '@shared/components'
import { useEvents } from '@shared/events'
import { ContactChannel, Reason } from '../../../../activation-register/activation-register.types'
import { useRegister, useRegisterSteps } from '../../../hooks'
import { ActionsContainer } from './register-step.styles'

const RegisterStep: React.FC = () => {
  const register = useRegister()
  const steps = useRegisterSteps()
  const events = useEvents()
  const [reason, setReason] = useState<Reason | undefined>(undefined)

  const onSecondPart = register.text

  const { data, loading } = useApiData<Reason[]>({
    dataSource: 'getReasonsActivationRecord',
    params: { showInActivationRecords: true },
  })

  const { value: showContactChannel } = useFeatureFlag('contactChannel', false)

  const onClickContinue = async (): Promise<void> => {
    if (reason) {
      await register.dispatch({
        type: 'change-reason',
        payload: {
          success: true,
          text: reason.reason,
          id: reason.id,
        },
      })

      if (!showContactChannel) sendRegister()
    }
  }

  const handleReasonChanged = (reason: Reason) => (): undefined => {
    setReason(reason)

    if (!showContactChannel) {
      register.dispatch({
        type: 'change-reason',
        payload: {
          success: true,
          text: reason.reason,
          id: reason.id,
        },
      })
    }
  }

  const handleChannelChanged = (channel: ContactChannel) => (): undefined => {
    register.dispatch({
      type: 'change-channel',
      payload: {
        channel,
      },
    })
  }

  const handleObservationChanged = (event: ChangeEvent<HTMLTextAreaElement>) => {
    register.dispatch({
      type: 'change-observation',
      payload: {
        observation: event.target.value,
      },
    })
  }

  const sendRegister = async () => {
    const ok = await register.finish()

    if (ok) {
      window.dispatchEvent(new CustomEvent('segmentation:close-activation-register'))
      steps.reset()
      Toaster.notify({
        kind: 'success',
        origin: 'right-top',
        description: pt.registerModal.registerSuccess,
      })
    } else {
      Toaster.notify({
        kind: 'error',
        origin: 'right-top',
        description: pt.registerModal.registerFailed,
      })
    }
  }

  const onClickButtonBack = (): void => {
    register.dispatch({
      type: 'reset-state',
    })

    if (!onSecondPart) {
      setReason(undefined)
      steps.back()
      events.clickRegisterBack()
    }
  }

  if (loading) {
    return <LoadingState isLoading={loading} containerHeight="140px" />
  }

  if (onSecondPart && showContactChannel) {
    return (
      <Flex direction="column" gap="$3">
        <Flex direction="column" gap="$2">
          <Typography fontSize="bodySmallShort" fontWeight="medium">
            {pt.registerModal.channelLabel}
          </Typography>
          <Flex gap="$1">
            <FloraButton
              size="small"
              hierarchy={register.channel === ContactChannel.WHATSAPP ? 'primary' : 'secondary'}
              onClick={handleChannelChanged(ContactChannel.WHATSAPP)}
              disabled={register.loading}
            >
              {pt.registerModal.channels.whatsapp}
            </FloraButton>
            <FloraButton
              size="small"
              hierarchy={register.channel === ContactChannel.PHONE ? 'primary' : 'secondary'}
              onClick={handleChannelChanged(ContactChannel.PHONE)}
              disabled={register.loading}
            >
              {pt.registerModal.channels.phone}
            </FloraButton>
            <FloraButton
              size="small"
              hierarchy={register.channel === ContactChannel.EMAIL ? 'primary' : 'secondary'}
              onClick={handleChannelChanged(ContactChannel.EMAIL)}
              disabled={register.loading}
            >
              {pt.registerModal.channels.email}
            </FloraButton>
          </Flex>
        </Flex>
        <Flex gap="$1">
          <Textarea
            id="observation-activation-register"
            label={pt.registerModal.observationLabel}
            labelPosition="external"
            onChange={handleObservationChanged}
            maxLength={260}
          />
        </Flex>
        <ActionsContainer>
          <FloraButton
            size="small"
            hierarchy="tertiary"
            disabled={register.loading}
            onClick={onClickButtonBack}
          >
            {pt.registerModal.back}
          </FloraButton>

          <FloraButton
            size="small"
            disabled={!register.isValid || register.loading}
            onClick={sendRegister}
          >
            {pt.registerModal.register}
          </FloraButton>
        </ActionsContainer>
      </Flex>
    )
  }

  return (
    <Flex direction="column" gap="$3">
      <Flex direction="column" align="start" gap="$2">
        <Typography fontSize="bodySmallShort" fontWeight="medium">
          {pt.registerModal.reasonLabel}
        </Typography>
        {data?.map((reasonItem, index) => (
          <Button
            key={index + reasonItem.reason}
            size="small"
            hierarchy={reason?.reason === reasonItem.reason ? 'primary' : 'secondary'}
            onClick={handleReasonChanged(reasonItem)}
            disabled={register.loading}
          >
            {reasonItem.reason}
          </Button>
        ))}
      </Flex>

      <ActionsContainer>
        <FloraButton
          size="small"
          hierarchy="tertiary"
          disabled={register.loading}
          onClick={onClickButtonBack}
        >
          {pt.registerModal.back}
        </FloraButton>

        {showContactChannel ? (
          <FloraButton
            size="small"
            disabled={!reason || register.loading}
            onClick={onClickContinue}
          >
            {pt.registerModal.continue}
          </FloraButton>
        ) : (
          <FloraButton size="small" disabled={!reason || register.loading} onClick={sendRegister}>
            {pt.registerModal.register}
          </FloraButton>
        )}
      </ActionsContainer>
    </Flex>
  )
}

const RegisterStepError: React.FC = () => {
  const fieldId = useId()
  const register = useRegister()
  const steps = useRegisterSteps()
  const events = useEvents()

  const onClickButtonBack = (): void => {
    steps.back()
    events.clickRegisterBack()
  }

  const sendRegister = async () => {
    const ok = await register.finish()

    if (ok) {
      window.dispatchEvent(new CustomEvent('segmentation:close-activation-register'))
      steps.reset()
      Toaster.notify({
        kind: 'success',
        origin: 'right-top',
        description: pt.registerModal.registerSuccess,
      })
    } else {
      Toaster.notify({
        kind: 'error',
        origin: 'right-top',
        description: pt.registerModal.registerFailed,
      })
    }
  }

  return (
    <Flex direction="column" gap="$3">
      <Textarea
        label={pt.activationRegister.registerStepError.fieldLabel}
        labelPosition="external"
        id={fieldId}
        disabled={register.loading}
        minLength={15}
        maxLength={300}
        value={register.text || ''}
        status={register.isValid ? undefined : 'error'}
        helperText={register.isValid ? '' : pt.activationRegister.registerStepError.minChar}
        placeholder={pt.activationRegister.registerStepError.fieldPlaceholder}
        onChange={(e) =>
          register.dispatch({
            type: 'change-reason',
            payload: { text: e.target.value, id: undefined, success: false },
          })
        }
      />
      <ActionsContainer>
        <FloraButton
          size="small"
          hierarchy="tertiary"
          disabled={register.loading}
          onClick={onClickButtonBack}
        >
          {pt.registerModal.back}
        </FloraButton>

        <FloraButton
          size="small"
          disabled={!register.text || register.loading}
          onClick={sendRegister}
        >
          {pt.registerModal.register}
        </FloraButton>
      </ActionsContainer>
    </Flex>
  )
}

export { RegisterStep, RegisterStepError }
