import { ScrollArea } from '@grupoboticario/flora-react'

import { objectivesDrawer } from '@objectives-filter/config'
import { resellerDetailDrawer, ResellerList } from '@reseller-list/index'
import { SectorsFilter, SectorsProvider, SectorsType } from '@sectors-filter/index'

import { pt } from '@shared/locales'
import { useDrawerContext } from '@shared/providers'

import { ActivationRegister } from './activation-register'
import { AppContainer, AppContent, AppWrapper, globalStyles, Title } from './app.styles'

const App: React.FC = () => {
  globalStyles()
  const drawer = useDrawerContext()
  drawer.register('objectives', objectivesDrawer)
  drawer.register('reseller-detail', resellerDetailDrawer)

  return (
    <ScrollArea orientation="vertical" type="auto" css={{ height: '100vh' }}>
      <AppWrapper>
        <AppContainer>
          <AppContent>
            <SectorsProvider>
              <Title>{pt.app.title}</Title>
              <SectorsFilter />
              <ResellerList middle={<SectorsType />} />
              <ActivationRegister />
            </SectorsProvider>
          </AppContent>
        </AppContainer>
      </AppWrapper>
    </ScrollArea>
  )
}

export { App }
