import { ActionsContainer, CheckboxContainer, CopyStepContainer } from './copy-step.styles'

import { FloraButton, FloraCheckbox, Text } from '@grupoboticario/flora-react'
import { pt } from '@shared/locales'

import { useEvents } from '@shared/events'
import { machine } from '../../../config/steps'
import { useRegisterSteps } from '../../../hooks'
import { useRegisterContext } from '../../../providers'
import { resellersToClipboard } from './resellers-to-clipboard'

export const CopyStep: React.FC = () => {
  const steps = useRegisterSteps()
  const events = useEvents()
  const { state } = useRegisterContext()

  const onCopy = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    steps.setList(machine.copy.on.next)

    const formData = new FormData(e.currentTarget)
    const toCopyColumns = ['id', ...formData.getAll('copy')] as string[]
    events.clickCopyFields(toCopyColumns.join('_'))

    await resellersToClipboard(toCopyColumns, state.data)
  }

  const onCancel = (): void => {
    events.clickCancelCopyFields()
    window.dispatchEvent(new CustomEvent('segmentation:close-activation-register'))
  }

  return (
    <form onSubmit={onCopy}>
      <CopyStepContainer>
        <Text size="bodySmallShortMedium" weight="medium">
          {pt.activationRegister.copyStep.bodyTitle}
        </Text>
        <CheckboxContainer>
          <FloraCheckbox
            id="resellerId"
            value="id"
            name="copy"
            label={pt.activationRegister.copyStep.resellerCode}
            disabled
            checked
          />
          <FloraCheckbox
            id="name"
            value="name"
            name="copy"
            label={pt.activationRegister.copyStep.name}
          />
          <FloraCheckbox
            id="phone"
            value="phone"
            name="copy"
            label={pt.activationRegister.copyStep.phone}
          />
          <FloraCheckbox
            id="credit"
            value="creditBalance"
            name="copy"
            label={pt.activationRegister.copyStep.availableCredit}
          />
          <FloraCheckbox
            id="fidelity"
            value="nextRangeValue"
            name="copy"
            label={pt.activationRegister.copyStep.nextFidelityTierValue}
          />
        </CheckboxContainer>
        <ActionsContainer>
          <FloraButton hierarchy="tertiary" size="small" onClick={onCancel}>
            {pt.activationRegister.copyStep.cancel}
          </FloraButton>
          <FloraButton hierarchy="primary" size="small" type="submit">
            {pt.activationRegister.copyStep.copy}
          </FloraButton>
        </ActionsContainer>
      </CopyStepContainer>
    </form>
  )
}
