import React, { ChangeEvent, useRef } from 'react'

import { Dropdown, DropdownItem, Flex } from '@grupoboticario/flora-react'

import { ObjectivesFormHandler } from '@objectives-filter/objectives-filter.types'
import { useApiData } from '@shared/api'
import { FilterFieldReason } from '@shared/providers'

import { Reason, Subreason } from './reason-field.types'

type ReasonFieldProps = FilterFieldReason & ObjectivesFormHandler<string>

const ReasonField: React.FC<ReasonFieldProps> = ({ onChange, form, filters, label, id }) => {
  const [reasonFilter, subreasonFilter] = filters
  const [reasonLabel, subreasonLabel] = label.split(',')
  const [reasonFieldId, subreasonFieldId] = id.split(',')
  const reasonValue = form[reasonFilter.name] ?? ''
  const subreasonValue = form[subreasonFilter.name] ?? ''
  const subreasonsDict = useRef<{ [K: string]: Subreason[] }>({})
  const subreasons = subreasonsDict.current[reasonValue] ?? []

  const reasons = useApiData<Reason[]>({
    dataSource: 'getReasons',
    fetchOnMount: true,
    onSuccess: (data) => data.forEach((r) => (subreasonsDict.current[r.id] = r.subreasons)),
  })

  const handleReasonChange = (ev: ChangeEvent<HTMLInputElement>): void => {
    onChange({ [reasonFilter.name]: ev.target.value, [subreasonFilter.name]: '' })
  }

  const hadleSubreasonChange = (ev: ChangeEvent<HTMLInputElement>): void => {
    onChange({ [subreasonFilter.name]: ev.target.value })
  }

  return (
    <Flex gap="$4" direction="column">
      <Dropdown
        id={reasonFieldId}
        labelText={reasonLabel}
        disabled={reasons.loading}
        onChange={handleReasonChange}
        value={reasonValue}
      >
        {reasons.data?.map((r) => (
          <DropdownItem key={r.id} value={r.id}>
            {r.reason}
          </DropdownItem>
        ))}
      </Dropdown>

      <Dropdown
        id={subreasonFieldId}
        labelText={subreasonLabel}
        disabled={!subreasons.length}
        onChange={hadleSubreasonChange}
        value={subreasonValue}
      >
        {subreasons.map((r) => (
          <DropdownItem key={r.id} value={r.id}>
            {r.subreason}
          </DropdownItem>
        ))}
      </Dropdown>
    </Flex>
  )
}

export { ReasonField }
