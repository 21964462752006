import React from 'react'

import { FloraTypography as Typography } from '@grupoboticario/flora-react'

import { pt } from '@shared/locales'

import { SectorTag } from '@sectors-filter/components'
import { type Sector } from '../../../sectors-filter.types'
import { Checkbox, ScrollArea, Table } from './list-sectors.styles'

interface ListSectorsProps {
  onChangeSectors: (selecteds: number[]) => void
  sectorsSelected: number[]
  sectors: Sector[]
}

const ListSectors: React.FC<ListSectorsProps> = ({ sectorsSelected, onChangeSectors, sectors }) => {
  const isAllChecked = sectors.length > 0 && sectors.length === sectorsSelected.length

  const handleCheck = (sector: Sector) => () => {
    const isActive = sectorsSelected.includes(sector.sectorId)
    if (isActive)
      onChangeSectors(sectorsSelected.filter((sectorId) => sectorId !== sector.sectorId))
    else onChangeSectors([...sectorsSelected, sector.sectorId])
  }

  const handleCheckAll = (): void => {
    if (sectors.length === sectorsSelected.length) onChangeSectors([])
    else onChangeSectors(sectors.map((s) => s.sectorId))
  }

  const sectorsList = sectors.map((sector) => {
    const isChecked = sectorsSelected.includes(sector.sectorId)
    const variant = isChecked ? 'assorted9' : 'disabled'

    return (
      <tr key={sector.sectorId} onClick={handleCheck(sector)}>
        <td>
          <Checkbox checked={isChecked} />
        </td>
        <td>
          <SectorTag sector={sector.sectorId} variant={variant} />
        </td>
        <td>
          <Typography fontSize="bodySmallShort" fontWeight="medium">
            {sector.name}
          </Typography>
        </td>
        <td>
          <Typography fontSize="bodySmallShort" fontWeight="medium" color="$nonInteractiveAuxiliar">
            {pt.sectorsFilter[sector.responsibility]}
          </Typography>
        </td>
      </tr>
    )
  })

  return (
    <ScrollArea>
      <Table>
        <thead>
          <tr>
            <th scope="col" onClick={handleCheckAll}>
              <Checkbox checked={isAllChecked} aria-label={pt.sectorsFilter.selectUnselectAll} />
            </th>
            <th scope="col">
              <Typography
                fontSize="exceptionsRestricted"
                color="$nonInteractiveAuxiliar"
                fontWeight="medium"
              >
                {pt.sectorsFilter.sectorCode}
              </Typography>
            </th>
            <th scope="col">
              <Typography
                fontSize="exceptionsRestricted"
                color="$nonInteractiveAuxiliar"
                fontWeight="medium"
              >
                {pt.sectorsFilter.sectorName}
              </Typography>
            </th>
            <th>
              <Typography
                fontSize="exceptionsRestricted"
                color="$nonInteractiveAuxiliar"
                fontWeight="medium"
              >
                {pt.sectorsFilter.responsibility}
              </Typography>
            </th>
          </tr>
        </thead>

        <tbody>{sectorsList}</tbody>
      </Table>
    </ScrollArea>
  )
}

export { ListSectors }
