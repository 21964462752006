/* eslint-disable @typescript-eslint/no-explicit-any */
import { FloraTypography as Typography } from '@grupoboticario/flora-react'

import { NewTag } from '@shared/components'
import { FilterFieldBase, type FilterField } from '@shared/providers'

import {
  CheckboxField,
  DateRangeField,
  DropdownField,
  DropdownFieldAsync,
  InputField,
  ReasonField,
  SwitchField,
} from '../../components'
import { type ObjectivesFormHandler } from '../../objectives-filter.types'
import { Container, FormGroup } from './filter-fields.styles'

interface FilterFieldsProps extends ObjectivesFormHandler<any> {
  fields: FilterField[]
}

interface RenderFieldTypeProps {
  disabled: boolean
  field: FilterField
}

const FilterFields = ({ fields, ...props }: FilterFieldsProps): JSX.Element => {
  const renderFieldType = ({ disabled, field }: RenderFieldTypeProps): JSX.Element | null => {
    switch (field.type) {
      case 'dropdown':
        return <DropdownField disabled={disabled} {...field} {...props} />
      case 'dropdown-async':
        return <DropdownFieldAsync disabled={disabled} {...field} {...props} />
      case 'date-range':
        return <DateRangeField disabled={disabled} {...field} {...props} />
      case 'checkbox':
        return <CheckboxField disabled={disabled} {...field} {...props} />
      case 'switch':
        return <SwitchField disabled={disabled} {...field} {...props} />
      case 'input':
        return <InputField disabled={disabled} {...field} {...props} />
      case 'subtitle':
        return (
          <Typography fontSize="bodySmallShort" fontWeight="medium">
            {field.text}
          </Typography>
        )
      case 'newtag':
        return <NewTag upTo={field.upTo} />
      case 'tab-reason':
        return <ReasonField {...field} {...props} />
      default:
        return null
    }
  }

  const renderFields = fields.map((field, index) => {
    const disabled = (field as FilterFieldBase).isDisabled?.(props.form) ?? false

    return (
      <FormGroup key={index} gridType={field.grid === 'half' ? 'half' : 'full'}>
        {renderFieldType({ disabled, field })}
      </FormGroup>
    )
  })

  return <Container>{renderFields}</Container>
}

export { FilterFields }
