import React, { Fragment } from 'react'

import { CopyButton } from '@shared/components'

import { Flex, FloraTypography as Typography } from '@grupoboticario/flora-react'

interface ContentProps {
  description?: string
  content: JSX.Element | string
  copy?: boolean
  textToCopy?: string
  onCopy?: () => void
}

const Content: React.FC<ContentProps> = ({ content, copy, description, textToCopy, onCopy }) => {
  let validContent = content

  if (typeof content === 'string') {
    validContent = (
      <Flex align="center">
        <Typography
          as="p"
          fontSize="bodyLargeStandard"
          color="$nonInteractivePredominant"
          fontWeight="medium"
        >
          {content}
        </Typography>
        {copy && (
          <CopyButton
            content={content}
            description={`${description} copiado`}
            textToCopy={textToCopy}
            onCopy={onCopy}
          />
        )}
      </Flex>
    )
  }

  return <Fragment>{validContent}</Fragment>
}

export { Content, type ContentProps }
