interface StepStateMachine {
  on: Record<string, Step[]>
}

interface Step {
  id: string
  description: string
  status: StepStatus
  on?: Record<string, Step[]>
}

interface Reason {
  reason: string
  id: string
}

enum ContactChannel {
  WHATSAPP = 'whatsApp',
  PHONE = 'telefone',
  EMAIL = 'email',
}

type StepStatus = 'done' | 'await' | 'error' | 'current'

type GetStep = (status: StepStatus) => Step

export { ContactChannel, GetStep, Reason, Step, StepStateMachine, StepStatus }
