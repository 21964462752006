import { ReactNode } from 'react'
import { Container } from './table-container.styles'

interface TableContainerProps {
  children: ReactNode
}

export const TableContainer: React.FC<TableContainerProps> = ({ children }) => {
  return <Container>{children}</Container>
}
